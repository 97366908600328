.bootstrap-tagsinput {
  display: inline-block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  vertical-align: middle;
  margin-bottom: 10px;
  color: #333333;
  background-color: #f7f7f7;
  background-image: none;
  border: 1px solid #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  cursor: text;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100% !important;
    max-width: inherit;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .tag {
    margin-right: 2px;
    color: white;

    [data-role="remove"] {
      margin-left:8px;
      cursor:pointer;
      &:after{
        content: "x";
        padding:0px 2px;
      }
      &:hover {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        &:active {
          box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }
      }
    }
  }
}
