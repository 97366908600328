@import "bootstrap";
@import "flag-icon";
@import "bootstrap-select";
//@import "bootstrap-slider";
@import "font-awesome";
@import "animate";
@import "_bootstrap-datetimepicker";
@import "roboto";
@facebook-color:#3b5998;
@twitter-color:#55acee;
@google-color:#ffffff;
@vkontakte-color:#587ea3;
@youtube-color: #cc181e;
@telegram-color: #0088cc;
@whatsapp-color: #25D366;

/** BOOKS COLORS **/
@one-shot-color:#f06292;
@manga-color:#7986cb;
@manhwa-color:#81c784;
@manhua-color:#8d6e63;
@novel-color:#e57373;
@doujinshi-color:#ffb74d;
@oel-color: #ba68c8;
@serie-color:#7986cb;
@film-color:#81c784;
@ova-color:#ffb74d;
@clip-color:#8d6e63;
@dorama-color:#f06292;
@company-color:#7986cb;
@character-color:#ffb74d;
@person-color:#e57373;
@discussion-color:#81c784;
@list-color: #ba68c8;
@culture-color:#972759;
@game-color: #72dab7;
@other-color: #57585a;
@otaku-color: @brand-primary;
@system-color: #a6a7a9;
@hentai-color:#bd362f;
@star-color:#FFDC5E;
@user-color:@brand-primary;
img{
  min-height: 1px;
  min-width: 1px;
}
.pbl{
  text-align: center;
  padding: 10px;
  background-color: darken(@body-bg, 2%);
  border-radius:@border-radius-base;
  clear: both;
  border: 1px solid darken(@body-bg, 3%);
  &:before{
    content: 'Publicidad';
    font-size: 10px;
    width: 100%;
    display: block;
  }
}

@media (max-width: @screen-xs-max) {
  .pbl{
    margin: 0 -15px;
  }
  .file-container{
    padding-left: 0;
    padding-right: 0;
  }
  .file-content-container{
    padding-left: 0;
    padding-right: 0;
  }
}

.bg-one_shot{
  background-color: @one-shot-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-manga{
  background-color: @manga-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-manhwa{
  background-color: @manhwa-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-manhua{
  background-color: @manhua-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-novel{
  background-color: @novel-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-doujinshi{
  background-color: @doujinshi-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-oel{
  background-color: @oel-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-serie{
  background-color: @serie-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-film{
  background-color: @film-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-ova{
  background-color: @ova-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-clip{
  background-color: @clip-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-dorama{
  background-color: @dorama-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-company{
  background-color: @company-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-person{
  background-color: @person-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-character{
  background-color: @character-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-discussion{
  background-color: @discussion-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-list{
  background-color: @list-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-culture{
  background-color: @culture-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-game{
  background-color: @game-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-other{
  background-color: @other-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-otaku{
  background-color: @otaku-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-system{
  background-color: @system-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-hentai{
  background-color: @hentai-color;
  color: #fff;
  a{
    color: #fff;
  }
}

.bg-user{
  background-color: @user-color;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-one_shot{
  &:before {
    background-image: radial-gradient(at 10% 30%, @one-shot-color 0%, darken(@one-shot-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-manga{
  &:before {
    background-image: radial-gradient(at 10% 30%, @manga-color 0%, darken(@manga-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-manhwa{
  &:before {
    background-image: radial-gradient(at 10% 30%, @manhwa-color 0%, darken(@manhwa-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-manhua{
  &:before {
    background-image: radial-gradient(at 10% 30%, @manhua-color 0%, darken(@manhua-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-novel{
  &:before {
    background-image: radial-gradient(at 10% 30%, @novel-color 0%, darken(@novel-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-doujinshi{
  &:before {
    background-image: radial-gradient(at 10% 30%, @doujinshi-color 0%, darken(@doujinshi-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-oel{
  &:before {
    background-image: radial-gradient(at 10% 30%, @oel-color 0%, darken(@oel-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-serie{
  &:before {
    background-image: radial-gradient(at 10% 30%, @serie-color 0%, darken(@serie-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-film{
  &:before {
    background-image: radial-gradient(at 10% 30%, @film-color 0%, darken(@film-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-ova{
  &:before {
    background-image: radial-gradient(at 10% 30%, @ova-color 0%, darken(@ova-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-clip{
  background-image: radial-gradient(at 10% 30%, @clip-color 0%, darken(@clip-color, 30%) 100%);
  background-size: cover;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-dorama{
  &:before {
    background-image: radial-gradient(at 10% 30%, @dorama-color 0%, darken(@dorama-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-company{
  &:before {
    background-image: radial-gradient(at 10% 30%, @company-color 0%, darken(@company-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-person{
  &:before {
    background-image: radial-gradient(at 10% 30%, @person-color 0%, darken(@person-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-character{
  &:before {
    background-image: radial-gradient(at 10% 30%, @character-color 0%, darken(@character-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-discussion{
  &:before {
    background-image: radial-gradient(at 10% 30%, @discussion-color 0%, darken(@discussion-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}

.bg-shadow-list{
  &:before {
    background-image: radial-gradient(at 10% 30%, @list-color 0%, darken(@list-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-culture{
  &:before {
    background-image: radial-gradient(at 10% 30%, @culture-color 0%, darken(@culture-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-game{
  &:before {
    background-image: radial-gradient(at 10% 30%, @game-color 0%, darken(@game-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-other{
  &:before {
    background-image: radial-gradient(at 10% 30%, @other-color 0%, darken(@other-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-otaku{
  &:before {
    background-image: radial-gradient(at 10% 30%, @otaku-color 0%, darken(@otaku-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}

.bg-shadow-system{
  &:before {
    background-image: radial-gradient(at 10% 30%, @system-color 0%, darken(@system-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-hentai{
  &:before {
    background-image: radial-gradient(at 10% 30%, @hentai-color 0%, darken(@hentai-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.bg-shadow-user{
  &:before {
    background-image: radial-gradient(at 10% 30%, @user-color 0%, darken(@user-color, 30%) 100%);
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  a{
    color: #fff;
  }
}
.lbl-one_shot{
  color: @one-shot-color;
  border-color: @one-shot-color;
  &:hover{
    color: lighten(@one-shot-color,6%);
  }
  &:focus,&:active{
    color: darken(@one-shot-color,6%);
  }
}
.lbl-manga{
  color: @manga-color;
  border-color: @manga-color;
  &:hover{
    color: lighten(@manga-color,6%);
  }
  &:focus,&:active{
    color: darken(@manga-color,6%);
  }
}
.lbl-manhwa{
  color: @manhwa-color;
  border-color: @manhwa-color;
  &:hover{
    color: lighten(@manhwa-color,6%);
  }
  &:focus,&:active{
    color: darken(@manhwa-color,6%);
  }
}
.lbl-manhua{
  color: @manhua-color;
  border-color: @manhua-color;
  &:hover{
    color: lighten(@manhua-color,6%);
  }
  &:focus,&:active{
    color: darken(@manhua-color,6%);
  }
}
.lbl-novel{
  color: @novel-color;
  border-color: @novel-color;
  &:hover{
    color: lighten(@novel-color,6%);
  }
  &:focus,&:active{
    color: darken(@novel-color,6%);
  }
}
.lbl-doujinshi{
  color: @doujinshi-color;
  border-color: @doujinshi-color;
  &:hover{
    color: lighten(@doujinshi-color,6%);
  }
  &:focus,&:active{
    color: darken(@doujinshi-color,6%);
  }
}
.lbl-oel{
  color: @oel-color;
  border-color: @oel-color;
  &:hover{
    color: lighten(@oel-color,6%);
  }
  &:focus,&:active{
    color: darken(@oel-color,6%);
  }
}
.lbl-serie{
  color: @serie-color;
  border-color: @serie-color;
  &:hover{
    color: lighten(@serie-color,6%);
  }
  &:focus,&:active{
    color: darken(@serie-color,6%);
  }
}
.lbl-film{
  color: @film-color;
  border-color: @film-color;
  &:hover{
    color: lighten(@film-color,6%);
  }
  &:focus,&:active{
    color: darken(@film-color,6%);
  }
}
.lbl-ova{
  color: @ova-color;
  border-color: @ova-color;
  &:hover{
    color: lighten(@ova-color,6%);
  }
  &:focus,&:active{
    color: darken(@ova-color,6%);
  }
}
.lbl-clip{
  color: @clip-color;
  border-color: @clip-color;
  &:hover{
    color: lighten(@clip-color,6%);
  }
  &:focus,&:active{
    color: darken(@clip-color,6%);
  }
}
.lbl-dorama{
  color: @dorama-color;
  border-color: @dorama-color;
  &:hover{
    color: lighten(@dorama-color,6%);
  }
  &:focus,&:active{
    color: darken(@dorama-color,6%);
  }
}
.lbl-company{
  color: @company-color;
  border-color: @company-color;
  &:hover{
    color: lighten(@company-color,6%);
  }
  &:focus,&:active{
    color: darken(@company-color,6%);
  }
}
.lbl-character{
  color: @character-color;
  border-color: @character-color;
  &:hover{
    color: lighten(@character-color,6%);
  }
  &:focus,&:active{
    color: darken(@character-color,6%);
  }
}
.lbl-person{
  color: @person-color;
  border-color: @person-color;
  &:hover{
    color: lighten(@person-color,6%);
  }
  &:focus,&:active{
    color: darken(@person-color,6%);
  }
}
.lbl-discussion{
  color: @discussion-color;
  border-color: @discussion-color;
  &:hover{
    color: lighten(@discussion-color,6%);
  }
  &:focus,&:active{
    color: darken(@discussion-color,6%);
  }
}
.lbl-list{
  color: @list-color;
  border-color: @list-color;
  &:hover{
    color: lighten(@list-color,6%);
  }
  &:focus,&:active{
    color: darken(@list-color,6%);
  }
}
.lbl-culture{
  color: @culture-color;
  border-color: @culture-color;
  &:hover{
    color: lighten(@culture-color,6%);
  }
  &:focus,&:active{
    color: darken(@culture-color,6%);
  }
}

.lbl-game{
  color: @game-color;
  border-color: @game-color;
  &:hover{
    color: lighten(@game-color,6%);
  }
  &:focus,&:active{
    color: darken(@game-color,6%);
  }
}
.lbl-other{
  color: @other-color;
  border-color: @other-color;
  &:hover{
    color: lighten(@other-color,6%);
  }
  &:focus,&:active{
    color: darken(@other-color,6%);
  }
}

.lbl-otaku{
  color: @otaku-color;
  border-color: @otaku-color;
  &:hover{
    color: lighten(@otaku-color,6%);
  }
  &:focus,&:active{
    color: darken(@otaku-color,6%);
  }
}

.lbl-system{
  color: @system-color;
  border-color: @system-color;
  &:hover{
    color: lighten(@system-color,6%);
  }
  &:focus,&:active{
    color: darken(@system-color,6%);
  }
}
.lbl-hentai{
  color: @hentai-color;
  background: rgba(255, 255, 255, 0.6);
  border: 1px dashed rgba(255, 255, 255, 0.6);
  border-radius: @border-radius-base;
  padding: 5px 10px;
}
.books-list,.videos-list,.communities-list,.news-list{
  li{
    list-style: none;
    float: left;
    margin: 0 7px;
    a{
      text-transform: uppercase;
    }
    .active{
      font-weight: 500;
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
  }
}
.media{
  .media-left,.media-body,.media-right{
    vertical-align:middle;
    .discussion-date{
      color: @navbar-default-bg;
      font-size: 0.9rem;
    }
  }
  .media-object{
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 3rem;
  }
  .disqus-count{
    color: @brand-primary;
    span{
      color: @brand-primary;
    }
  }
  .disqus-count-small{
    font-size: 0.9rem;
  }
}

@media (max-width: @screen-sm-max) {
  .books-list-container,.videos-list-container,.communities-list-container,.news-list-container {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 60px;
    .books-list,.videos-list,.communities-list,.news-list {
      overflow-x: scroll;
      overflow-y: hidden;
      display: inline-flex;
      width: auto;
      text-align: center;
      div {
        padding:0 10px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        display: inline-block;
        a {
          color: inherit;
          white-space: nowrap;
          text-transform: uppercase;
          font-size: 26px;
          font-weight: 300;
          display: inline-block;
        }
        a.active {
          font-weight: 500;
        }
      }
      div.active{
        border-bottom-width: 5px;
        border-bottom-style: solid;
      }
    }
  }
  .sections-list-container {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 60px;
    .section-list {
      overflow-x: scroll;
      overflow-y: hidden;
      list-style: none;
      padding: 0;
      display: inline-flex;
      width: auto;
      text-align: center;
      li {
        padding:0 10px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        display: inline-block;
        a {
          color: @navbar-default-bg;
          white-space: nowrap;
          font-size: 26px;
          font-weight: 300;
          display: inline-block;
        }
      }
      li.active{
        border-bottom-width: 5px;
        border-bottom-style: solid;
        border-bottom-color: @brand-primary;
        a{
          font-weight: bold;
        }
      }
    }
  }
}
/*****************SCROLLBAR STYLE****************/

::-webkit-scrollbar-track
{
  border-radius: 0px;
  background-color: @body-bg;
}

::-webkit-scrollbar
{
  width: 10px;
  height: 6px;
  background-color: @body-bg;
}

::-webkit-scrollbar-thumb
{
  border-radius: 0px;
  background-color: @brand-primary;
}
  /** VIDEO COLORS **/

.makeRandom(@min: 0, @max: @min+1, @int: 0) {
  .checkInt() {
    @getNum: `Math.random() * (@{max} - @{min} + @{int})`;
    @base: unit(`@{int} == 1 ? Math.floor(@{getNum}) : @{getNum}`);
  }
  .checkInt();
  @randNum: @base + @min;
}

.alert{
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  border-radius:2px;
}
.alert-login{
  background: @brand-primary;
  border-color: transparent;
  color: #ffffff;
}

.alert-danger{
  background-color: #BD362F;
  border-color: transparent;
  color: #ffffff;
}
.alert-success{
  background-color: #51A351;
  border-color: transparent;
  color: #ffffff;
}
.alert-warning{
  background-color: #e8941d;
  border-color: transparent;
  color: #ffffff;
}
.alert-info{
  background-color: #2F96B4;
  border-color: transparent;
  color: #ffffff;
}

.badge-danger {
  background: @brand-danger;
}

.cbx-icon{
  color: @brand-primary;
}
.cbx-active:hover, .cbx-active:focus{
  border-color: #318BBE;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #318BBE;
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #318BBE ;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #318BBE;
}
.credit{
  background: #ffffff;
  padding: 5px 15px;
  margin: 5px 0;
  border-radius: 2px;
  border: 1px solid #ccc;
  display: inline-block;
}
.fa-stack-2x {
  margin-top: -7px;
}
.fa-times.fa-stack-2x{
  opacity:0.8;
}
::selection {
  background: darken(@brand-primary, 6.5%);
  color: #ffffff;
}

::-moz-selection {
  background: darken(@brand-primary, 6.5%);
  color: #ffffff;
}
.btn{
  border-radius: @border-radius-base;
  line-height: 40px;
  padding: 6px 18px;
  margin: 5px 0;
  outline: 0 !important;
}
.select2-container--default .select2-results>.select2-results__options{
  max-height: 350px !important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
  background: rgba(0,0,0,0.1) !important;
}
.select2-container{
  color: @text-color;
  border-color: @input-border;
  height: 50px !important;
  line-height: 50px !important;
  .selection {
    .select2-selection--single {
      height: 50px !important;
    }
    .select2-selection__rendered {
      color: #444;
      line-height: 50px !important;
      .select2-selection__placeholder {
        color: @input-color-placeholder;
      }
      .select2-selection__clear{
        font-size: 200%;
        width: 10%;
        text-align: center;
      }
      .select2-result-repository-type{
        text-transform: capitalize;
        border-width: 2px;
        border-style: solid;
        border-radius: @border-radius-base;
        padding: 0 10px;
        display: inline;
      }
      .select2-result-repository-title{
        text-transform: capitalize;
        font-weight: bold;
        display: inline;
      }
    }
    .select2-selection__arrow {
      height: 50px !important;
      b {
        border-color: @navbar-default-bg;
      }
    }
  }
  .select2-result-repository {
    height: 90px;
    .select2-result-left-col {
      width: 20%;
      text-align: center;
      display: inline-block;
      img{
        height: 100%;
        max-height: 90px;
      }
    }
    .select2-result-right-col {
      width: 70%;
      display: inline-block;
      .select2-result-repository-type{
        text-transform: capitalize;
        border-width: 2px;
        border-style: solid;
        border-radius: @border-radius-base;
        padding: 0 10px;
        line-height: 25px;
        display: table;
      }
      .select2-result-repository-title{
        line-height: 20px;
        text-transform: capitalize;
        font-size: 130%;
        color: @brand-primary;
      }
    }
  }
}

.btn-wish{
  color: @navbar-default-link-color;
  background-color: #0e67ef;
  &:visited,&:focus{
    color: @navbar-default-link-color;
    background-color: #0e67ef;
  }
  &:hover{
    color: lighten(@navbar-default-link-color,6%);
    background-color: lighten(#0e67ef,6%);
  }
  &:active{
    color: darken(@navbar-default-link-color,6%);
    background-color: darken(#0e67ef,6%);
  }
}

.btn-have{
  color: @navbar-default-link-color;
  background-color: #970047;
  &:visited,&:focus{
    color: @navbar-default-link-color;
    background-color: #970047;
  }
  &:hover{
    color: lighten(@navbar-default-link-color,6%);
    background-color: lighten(#970047,6%);
  }
  &:active{
    color: darken(@navbar-default-link-color,6%);
    background-color: darken(#970047,6%);
  }
}
.btn-default-dark{
  color: @navbar-default-link-color;
  background-color: @navbar-default-bg;
  &:visited,&:focus{
    color: @navbar-default-link-color;
    background-color: @navbar-default-bg;
  }
  &:hover{
    color: lighten(@navbar-default-link-color,6%);
    background-color: lighten(@navbar-default-bg,6%);
  }
  &:active{
    color: darken(@navbar-default-link-color,6%);
    background-color: darken(@navbar-default-bg,6%);
  }
}

.btn-default-stroke{
  color: @navbar-default-bg;
  background-color: transparent;
  border-color: @navbar-default-bg;
  &:hover{
    background: @navbar-default-bg;
    color: @navbar-default-link-color;
  }
}

.btn-primary-stroke{
  color: @brand-primary;
  background-color: transparent;
  border-color: @brand-primary;
  &:hover{
    background: @brand-primary;
    color: @navbar-default-link-color;
  }
}

.btn-success-stroke{
  color: @brand-success;
  background-color: transparent;
  border-color: @brand-success;
  &:hover{
    background: @brand-success;
    color: @navbar-default-link-color;
  }
}

.btn-danger-stroke{
  color: @brand-danger;
  background-color: transparent;
  border-color: @brand-danger;
  &:hover{
    background: @brand-danger;
    color: @navbar-default-link-color;
  }
}

.btn-warning-stroke{
  color: @brand-warning;
  background-color: transparent;
  border-color: @brand-warning;
  &:hover{
    background: @brand-warning;
    color: @navbar-default-link-color;
  }
}

.btn-info-stroke{
  color: @brand-info;
  background-color: transparent;
  border-color: @brand-info;
  &:hover{
    background: @brand-info;
    color: @navbar-default-link-color;
  }
}
.btn-copy-clipboard{
  height: 50px;
  margin: 0;
}
.elements-pagination{
  span{
    font-size: 1.9rem;
    line-height: 1.80;
  }
}
.home-personal-section{
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius:2px;
  padding:15px 10px;
  margin: 0 10px;
}
body {
  font-family: 'Roboto';
}
.backgrounded{
  &:before{
    background-color: @body-bg;
    position: absolute;
    filter: opacity(0.2);
    background-position: center center;
    content: '';
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}
.file-hentai{
  background-color: #845a5a !important;
  border: 1px solid @hentai-color !important;
  .thumbnail{
    filter: blur(10px);
    transform:scale(0.9);
  }
  .icon{
    &:before{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      content: "HENTAI";
      font-size: 25px;
      position: absolute;
      color: @hentai-color;
      top: 100px;
      left: 25%;
      height: 100%;
      width: 100%;
      text-shadow: 0 0 5px black;
    }
  }
}
.file-thumbnail{
  margin: 10px 0;
  height:250px;
  background-color: @navbar-default-bg;
  position: relative;
  background-position: center center;
  border-color: #e1eadb;
  border: 0px solid transparent;
  border-radius: 2px;
  background-size: cover;
  .thumbnail{
    background-position: center center;
    background-size: cover;
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    overflow: hidden;
  }
  .front, .back {
    position: absolute;
    left: 0;
    width:100%;
    text-shadow: 1px 1px 0px black;
  }
  .front {
    display: block;
    top: 0;
    height:100%;
    .title {
      background-color: rgba(0, 0, 0, .6);
      color: #ffffff;
      border: 2px solid transparent;
      padding: 10px 5px;
      text-transform: uppercase;
      font-size: 1.1rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .score{
      &:before{
        font-family: FontAwesome;
        content: "\f005";
        color: @star-color;
        margin-right: 5px;
      }
      position: absolute;
      left:5px;
      color: #ffffff;
      font-weight: bold;
      top: 45px;
    }
    .type{
      position: absolute;
      right:5px;
      color: #ffffff;
      font-weight: bold;
      top: 45px;
    }
  }
  .back{
    position: absolute;
    bottom:0;
    background-color: rgba(0, 0, 0, .65);
    color: #ffffff;
    height: 0;
    padding:0;
    margin:0;
    visibility: hidden;
    display: none;
    font-size: 1.05rem;
    transition: .5s;
    -webkit-animation:expand .5s;
    -o-animation:expand .5s;
    animation:expand .5s;
    .overview{
      visibility: hidden;
      display: -webkit-box;
      max-width: 500px;
      -webkit-line-clamp: 12;
      font-size: 1.2rem;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:hover{
    .front{
      display: none;
    }
    .back{
      height:100%;
      padding: 10px 5px;
      visibility: visible;
      display: block;
      .overview{
        animation: fadeIn 1s;
        visibility: visible;
      }
    }
  }
}
.company-thumbnail,.character-thumbnail,.person-thumbnail,.discussion-thumbnail{
  margin: 10px 0;
  height:250px;
  min-height: 125px;
  background-color: @navbar-default-bg;
  position: relative;
  background-position: center center;
  border-color: #e1eadb;
  border: 0px solid transparent;
  border-radius: 2px;
  background-size: cover;
  .thumbnail{
    background-position: center center;
    background-size: cover;
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    overflow: hidden;
  }
  .title {
    background-color: rgba(0, 0, 0, .6);
    text-shadow: 1px 1px 0px black;
    color: #ffffff;
    border: 2px solid transparent;
    padding: 10px 5px;
    text-transform: uppercase;
    font-size: 1.1rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
@media (min-width: 2560px) {
  .company-thumbnail,.character-thumbnail,.person-thumbnail,.discussion-thumbnail{
    height: 400px;
  }
}

.file-container{
  margin:0;
  padding:0;
  height: 100%;
  overflow: hidden;
  clear: both;
  .manga {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @manga-color 0%, darken(@manga-color, 30%) 100%);
    }
  }
  .manhwa {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @manhwa-color 0%, darken(@manhwa-color, 30%) 100%);
    }
  }
  .manhua {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @manhua-color 0%, darken(@manhua-color, 30%) 100%);
    }
  }
  .one_shot {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @one-shot-color 0%, darken(@one-shot-color, 30%) 100%);
    }
  }
  .doujinshi {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @doujinshi-color 0%, darken(@doujinshi-color, 30%) 100%);
    }
  }
  .novel {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @novel-color 0%, darken(@novel-color, 30%) 100%);
    }
  }
  .oel {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @oel-color 0%, darken(@oel-color, 30%) 100%);
    }
  }
  .clip {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @clip-color 0%, darken(@clip-color, 30%) 100%);
    }
  }
  .film {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @film-color 0%, darken(@film-color, 30%) 100%);
    }
  }
  .serie {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @serie-color 0%, darken(@serie-color, 30%) 100%);
    }
  }
  .ova {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @ova-color 0%, darken(@ova-color, 30%) 100%);
    }
  }
  .dorama {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @dorama-color 0%, darken(@dorama-color, 30%) 100%);
    }
  }
  .hentai {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @hentai-color 0%, darken(@hentai-color, 30%) 100%) !important;
    }
  }
  .person {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @person-color 0%, darken(@person-color, 30%) 100%) !important;
    }
  }
  .company {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @company-color 0%, darken(@company-color, 30%) 100%) !important;
    }
  }
  .discussion {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @discussion-color 0%, darken(@discussion-color, 30%) 100%) !important;
    }
  }
  .character {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @character-color 0%, darken(@character-color, 30%) 100%) !important;
    }
  }
  .list {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @list-color 0%, darken(@list-color, 30%) 100%) !important;
    }
  }
  .culture {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @culture-color 0%, darken(@culture-color, 30%) 100%) !important;
    }
  }
  .game {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @game-color 0%, darken(@game-color, 30%) 100%) !important;
    }
  }
  .other {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @other-color 0%, darken(@other-color, 30%) 100%) !important;
    }
  }
  .system {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @system-color 0%, darken(@system-color, 30%) 100%) !important;
    }
  }
  .user {
    .background:before {
      background-image: radial-gradient(at 10% 30%, @user-color 0%, darken(@user-color, 30%) 100%) !important;
    }
  }
  .file-header{
    height: 100%;
    overflow: hidden;
    clear: both;
    position: relative;
    padding-bottom: 25px;
    .background {
      height: 100%;
      width:100%;
      position: absolute;
      top:0;
      left:0;
      z-index: -2;
      &:before {
        background-size: cover;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        z-index: -2;
        display: block;
        will-change: opacity;
        filter: opacity(100%);
        transition: filter 1s;
      }
    }
    .wallpaper {
      height: 100%;
      width:100%;
      position: absolute;
      top:0;
      left:0;
      z-index: -1;
      &:before {
        background-size: cover;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
        display: block;
        will-change: opacity;
        filter: opacity(20%) grayscale(1);
        transition: filter 1s;
        background-position: center center;
      }
    }
    .container{
      .file-type{
        text-transform: uppercase;
        padding: 0px 24%;
        border-radius: 0;
        font-weight: 300;
        font-size: 60%;
      }
      .file-title{
        font-weight: bold;
        .male{
          color: #bbdefb;
        }
        .female{
          color: #f8bbd0;
        }
      }
      .file-main-title{
        font-size: 1.8rem;
        color: #303841;
        font-weight: 300;
        text-transform: uppercase;
      }
      .file-poster-container {
        position: relative;
        display: block;
        .file-poster {
          width:100%;
          display: inline-block;
          box-shadow: 0px 0px 5px @navbar-default-bg;
          margin: 0 auto;
        }
        .file-poster-elements{
          background: rgba(0,0,0,0.6);
          height: 50px;
          position: absolute;
          bottom: 0;
          width:100%;
          display: block;
        }
        .file-rating-container {
          .file-score {
            text-align: right;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 20px;
            line-height: 20px;
            background-color: rgba(0,0,0,0.6);
            padding: 5px;
            border-radius: 2px;
            color: #ffffff;
            font-weight: bold;
            &:before {
              font-family: FontAwesome;
              content: "\f005";
              color: @star-color;
              margin-right: 5px;
            }
          }
          .file-rating {

          }
          &:hover {

          }
        }
        .file-status-container {
          .user-stats{
            width:100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            left:0;
            font-size: 15px;
            line-height: 15px;
            background-color: rgba(0, 0, 0, 0.7);
            padding: 10px;
            border-radius: 0 0 2px 2px;
            color: #ffffff;
            text-transform:capitalize;
            &:before {
              font-family: FontAwesome;
              content: "\f1fe";
              margin-right: 5px;
            }
            &.new {
              &:before {
                color: @brand-info;
              }
            }
            &.beginner{
              &:before {
                color: @brand-primary;
              }
            }
            &.advanced {
              &:before {
                color: @brand-warning;
              }
            }
            &.expert {
              &:before {
                color: @brand-success;
              }
            }
          }
          .file-status {
            width:100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            left:0;
            font-size: 20px;
            line-height: 20px;
            background-color: rgba(0, 0, 0, 0.7);
            padding: 10px;
            border-radius: 0 0 2px 2px;
            color: #ffffff;
            text-transform:capitalize;
            &.rumored,&.released,&.planned,&.in_production,&.in_emision,&.post_production,&.returning_series,&.pilot,&.ended,&.cancelled,&.publishing,&.on_hold {
              &:before {
                font-family: FontAwesome;
                content: "\f111";
                margin-right: 5px;
              }
            }
            &.ended {
              &:before {
                color: @brand-danger;
              }
            }
            &.cancelled{
              &:before {
                color: #782DB2;
              }
            }
            &.in_production,&.in_emision,&.publishing,&.released{
              &:before {
                color: @brand-success;
              }
            }
            &.rumored,&.planned,&.post_production{
              &:before {
                color: @brand-info;
              }
            }
            &.returning_series,&.in_production,&.on_hold{
              &:before {
                color: @brand-warning;
              }
            }
          }
        }
      }
      color: @body-bg !important;
      text-shadow: 1px 1px 0 @navbar-default-bg !important;
      h1,h2,h3,h4,h5{
        color: @body-bg !important;
        font-weight: bold;
      }
      a{
        color: @body-bg;
      }
    }
  }
}
.file-section-header{
  height: 100%;
  overflow: hidden;
  clear: both;
  position: relative;
  padding-bottom: 25px;
  .background {
    height: 100%;
    width:100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -2;
    &:before {
      background-size: cover;
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: -2;
      display: block;
      will-change: opacity;
      filter: opacity(100%);
      transition: filter 1s;
    }
  }
  .wallpaper {
    height: 100%;
    width:100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    &:before {
      background-size: cover;
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      display: block;
      will-change: opacity;
      filter: opacity(20%) grayscale(1);
      transition: filter 1s;
      background-position: center center;
    }
  }
  .container{
    .file-type{
      text-transform: uppercase;
      padding: 0px 24%;
      border-radius: 0;
      font-weight: 300;
      font-size: 60%;
    }
    .file-title{
      font-weight: bold;
    }
    .file-main-title{
      font-size: 1.8rem;
      color: #303841;
      font-weight: 300;
      text-transform: uppercase;
    }
    .file-poster-container {
      position: relative;
      margin-top: 20px;
      display: block;
      .file-poster {
        width:100%;
        display: inline-block;
        box-shadow: 0px 0px 5px @navbar-default-bg;
        margin: 0 auto;
      }
      .file-poster-elements{
        background: rgba(0,0,0,0.6);
        height: 50px;
        position: absolute;
        bottom: 0;
        width:100%;
        display: block;
      }
      .file-rating-container {
        .file-score {
          text-align: right;
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 20px;
          line-height: 20px;
          background-color: rgba(0,0,0,0.6);
          padding: 5px;
          border-radius: 2px;
          color: #ffffff;
          font-weight: bold;
          &:before {
            font-family: FontAwesome;
            content: "\f005";
            color: @star-color;
            margin-right: 5px;
          }
        }
        .file-rating {

        }
        &:hover {

        }
      }
    }
    color: @body-bg !important;
    text-shadow: 1px 1px 0 @navbar-default-bg !important;
    h1,h2,h3,h4,h5{
      color: @body-bg !important;
      font-weight: bold;
    }
  }
}
.file-content{
  .file-sidebar-container{
    .file-sidebar{
      width:200px;
      margin:0 auto;
      .file-sidebar-menu{
        list-style: none;
        padding: 0;
        li:not(.poster){
          padding: 5px 15px;
          border-bottom: 1px solid darken(@body-bg,13%);
          a{
            display: block;
            text-transform: uppercase;
            color: @text-color;
            padding:0;
            background: transparent;
          }
          &:hover{
            background: transparent;
            a {
              color: lighten(@text-color,6%);
              background: transparent;
            }
          }
          &.active {
            a {
              color: @navbar-default-link-active-color;
              background: transparent;
            }
          }
        }
        .menu-element{
          width: 100%;
        }
        .poster {
          visibility: hidden;
          width:100%;
          .file-score {
            text-align: right;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 15px;
            line-height: 15px;
            background-color: rgba(0,0,0,0.6);
            padding: 5px;
            border-radius: 2px;
            color: #ffffff;
            font-weight: bold;
            text-shadow: 1px 1px 0 @navbar-default-bg;
            &:before {
              font-family: FontAwesome;
              content: "\f005";
              color: @star-color;
              margin-right: 5px;
            }
          }
          .file-status-container {
            .user-stats{
              width:100%;
              text-align: center;
              position: absolute;
              bottom: 0;
              left:0;
              font-size: 15px;
              line-height: 15px;
              background-color: rgba(0, 0, 0, 0.7);
              padding: 10px;
              border-radius: 0 0 2px 2px;
              color: #ffffff;
              text-transform:capitalize;
              &:before {
                font-family: FontAwesome;
                content: "\f1fe";
                margin-right: 5px;
              }
              &.new {
                &:before {
                  color: @brand-info;
                }
              }
              &.beginner{
                &:before {
                  color: @brand-primary;
                }
              }
              &.advanced {
                &:before {
                  color: @brand-warning;
                }
              }
              &.expert {
                &:before {
                  color: @brand-success;
                }
              }
            }
            .file-status {
              width:100%;
              text-align: center;
              position: absolute;
              bottom: 0;
              left:0;
              font-size: 15px;
              line-height: 15px;
              background-color: rgba(0, 0, 0, 0.7);
              padding: 10px;
              border-radius: 0 0 2px 2px;
              color: #ffffff;
              text-transform:capitalize;
              &.rumored,&.released,&.planned,&.in_production,&.in_emision,&.post_production,&.returning_series,&.pilot,&.ended,&.cancelled,&.publishing,&.on_hold {
                &:before {
                  font-family: FontAwesome;
                  content: "\f111";
                  margin-right: 5px;
                }
              }
              &.ended {
                &:before {
                  color: @brand-danger;
                }
              }
              &.cancelled{
                &:before {
                  color: #782DB2;
                }
              }
              &.in_production,&.in_emision,&.publishing,&.released{
                &:before {
                  color: @brand-success;
                }
              }
              &.rumored,&.planned,&.post_production{
                &:before {
                  color: @brand-info;
                }
              }
              &.returning_series,&.in_production,&.on_hold{
                &:before {
                  color: @brand-warning;
                }
              }
            }
          }
        }
      }
      &.affix {
        top: 135px;
        position: fixed;
        margin-top: 0;
        .poster {
          visibility: visible;
          display: inline-block;
        }
        .menu-element{

        }
      }
      &.affix-top {
        position: relative;
        .poster {
          visibility: hidden;
          display: none;
        }
        .menu-element{

        }
      }
      &.affix-bottom {
        top: 0;
        position: relative;
        margin: 0;
        .poster {
          display: inline-block;
          visibility: visible;
        }
        .menu-element{

        }
      }
    }
  }
  .file-content-container{
    .section-title{
      margin-top:45px;
    }
    .season-poster,.volume-cover,.episode-image{
      margin:15px auto;
      img{
        width: 100%;
      }
    }
    .no-filled-container{
      line-height: 50px;
    }
    .changes-container{
      .change-icon{
        &.add{
          &:before{
            font-family: FontAwesome;
            content: "\f196 \A";
            font-size: 3rem;
            color:@gray-dark;
          }
        }
        &.create{
          &:before{
            font-family: FontAwesome;
            content: "\f067 \A";
            font-size: 3rem;
            color:@gray-dark;
          }
        }
        &.update{
          &:before{
            font-family: FontAwesome;
            content: "\f021 \A";
            font-size: 3rem;
            color:@gray-dark;
          }
        }
        &.delete{
          &:before{
            font-family: FontAwesome;
            content: "\f1f8 \A";
            font-size: 3rem;
            color:@gray-dark;
          }
        }
      }
      .change-action{
        color: @brand-primary;
        margin:0;
      }
      .change-owner{
        color: @gray-dark;
      }
      .change-date{
        color: @gray-dark;
      }
      .change-value{
        overflow-wrap: break-word;
        ol{
          margin: 0;
          padding-left: 20px;
        }
        &.well-danger{
          background-color: fadeout(@alert-danger-bg,15%);
          border: none;
          color: #ffffff;
        }
        &.well-success{
          background-color: fadeout(@alert-success-bg,15%);
          border: none;
          color: #ffffff;
        }
      }
    }
    .news-container{
      background: rgba(0,0,0,0.3);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      height:120px;
      text-shadow: 1px 1px 0 @navbar-default-bg !important;
      a{
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0px;
        position: absolute;
      }
      .news-title{
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
      .news-description{
        color: #ffffff;
      }
      .text-truncate{
        -webkit-line-clamp: 3;
        width: auto;
        max-width: 100%;
      }
      .news-owner{
        &:before{
          font-family: FontAwesome;
          content: "\f2be";
          margin-right: 5px;
        }
        position: absolute;
        left: 5px;
        bottom: 5px;
        padding: 0;
        margin: 0;
        color: #d8dbdf;
        font-size: 11px;
      }
      .news-date{
        &:before{
          font-family: FontAwesome;
          content: "\f073";
          margin-right: 5px;
        }
        position: absolute;
        right: 5px;
        bottom: 5px;
        padding: 0;
        margin: 0;
        color: #d8dbdf;
        font-size: 11px;
      }
    }
    .review-container{
      .overall-score{
        position: relative;
        border-radius: 50%;
        .score{
          color: @navbar-default-bg !important;
          line-height: 75px;
          font-weight: 300;
          font-size: 40px;
          position: relative;
        }
        &:before{
          position: absolute;
          left: 0;
          top:0;
          width: 100%;
          height: 100%;
          color: @star-color;
          opacity: 0.6;
          line-height: 75px;
          font-size: 90px;
          font-family: FontAwesome;
          content: '\f005';
        }
      }
      .review-thumbnail{
        .fa-quote-left{
          color: @navbar-default-link-color;
        }
        .text-truncate{
          -webkit-line-clamp: 6;
        }
      }
    }
    .recommendation-container{
      .recommendation-thumbnail{
        .picture{
          width:100%;
        }
      }
      .recommendation-content{
        hr{
          border-top-color: @body-bg;
        }
        .recommendation-title{
          margin: 0;
        }
        .recommendation-user{
          color: @navbar-default-bg;
        }
        .fa-quote-left{
          color: @navbar-default-link-color;
        }
        .text-truncate{
          -webkit-line-clamp: 4;
        }
        .recommendation-details{
          .recommendation-comment{
            background: fade(@body-bg,50%);
            padding: 15px 15px 0 20px;
            border: 1px solid transparent;
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
          }
          .btn-vote {
            cursor: pointer;
            display: inline-block;
            padding: 0 5px;
            .fa-thumbs-up {
              font-size: 1.5em;
              color: @alert-success-bg;
            }
            .fa-thumbs-down {
              font-size: 1.5em;
              color: @alert-danger-bg;
            }
          }
        }
      }
    }
    .file-data-container{
      .file-data{
        padding: 5px;
        .file-data-title {
          color: @navbar-default-bg;
          font-weight: bold;
        }
      }
    }
    .file_statuses{
      display: block;
      .file-status-element {
        padding: 15px 0;
        .watch, .wish, .have, .abandoned,.pending,.follow {
          border-width: 0;
          border-radius: @border-radius-base;
          border-style: solid;
          h3 {
            margin: 0;
            font-weight: bold;
            color: @navbar-default-bg;
          }
        }
        .watch {
          color: @brand-success !important;
          border-color: @brand-success;
          h1 {
            margin: 0;
            color: @text-color !important;
            i {
              color: @brand-success !important;
            }
          }
        }
        .wish {
          color: #0e67ef !important;
          border-color: #0e67ef;
          h1 {
            margin: 0;
            color: @text-color !important;
            i {
              color: #0e67ef !important;
            }
          }
        }
        .have {
          color: #970047 !important;
          border-color: #970047;
          h1 {
            margin: 0;
            color: @text-color !important;
            i {
              color: #970047 !important;
            }
          }
        }
        .abandoned {
          color: @brand-danger !important;
          border-color: @brand-danger;
          h1 {
            margin: 0;
            color: @text-color !important;
            i {
              color: @brand-danger !important;
            }
          }
        }
        .pending {
          color: @brand-warning !important;
          border-color: @brand-warning;
          h1 {
            margin: 0;
            color: @text-color !important;
            i {
              color: @brand-warning !important;
            }
          }
        }
        .follow {
          color: @brand-info !important;
          border-color: @brand-info;
          h1 {
            margin: 0;
            color: @text-color !important;
            i {
              color: @brand-info !important;
            }
          }
        }
      }
    }
    .media-container{
      .nav-pills{
        li{
          background: transparent;
          a{
            background: transparent;
            line-height: 1.6rem;
            font-size: 1.6rem;
            font-weight: 300;
            color: @navbar-default-bg;
            span{
              color: @navbar-default-bg;
            }
          }
          &.active{
            background: transparent;
            border-left-width: 2px;
            border-left-style: solid;
            border-left-color: @brand-primary;
            a{
              background: transparent;
              font-weight: bold;
              color: @navbar-default-bg;
              span{
                color: @navbar-default-bg;
              }
            }
          }
          &.cancel{
            a{
              background: transparent;
              font-weight: bold;
              color: @brand-danger !important;
              span{
                color: @brand-danger !important;
              }
            }
          }
          &:hover{
            background: transparent;
            a:hover{
              color: @navbar-default-bg;
              background: transparent;
              span{
                color: @navbar-default-bg;
              }
            }
          }
        }
      }
      .media-tabs{
        iframe{
          width: 100%;
          border: none;
        }
        .media-thumbnail{
          position: relative;
          margin: 5px 0;
          .picture{
            width:100%;
          }
          .media-alt{
            position: absolute;
            bottom: 0;
            background: rgba(0,0,0,0.6);
            color: #ffffff;
            text-shadow: 1px 1px 0 black;
            text-transform: uppercase;
            border: 2px solid transparent;
            padding: 10px 5px;
            font-size: 1.05rem;
            width:100%;
            &.poster,&.cover,&.wallpaper{
              .btn-vote {
                cursor: pointer;
                .fa-thumbs-up {
                  color: @alert-success-bg;
                }
                .fa-thumbs-down {
                  color: @alert-danger-bg;
                }
              }
            }
            &.wallpaper{
              /*&:before{
                font-family: FontAwesome;
                content: "\f03e";
                color: @brand-primary;
                margin-right: 5px;
              }*/
            }
            &.opening,&.ending,&.trailer{
              &:before{
                font-family: FontAwesome;
                content: "\f16a";
                color: #CD201F;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .season-container{
      .season-thumbnail{
        padding: 0;
        .picture{
          max-height: 100px;
          min-height: 70px;
          width: 100%;
        }
      }
      .season-data{
        line-height: 100%;
        .season-title{
          color: @navbar-default-bg;
          font-weight: bold;
        }
      }
    }
    .chapter-container,.episode-container{
      .attributes{
        padding: 20px;
        .attribute{
          color: @navbar-default-bg;
          font-weight: bold;
        }
        .value{
          font-size: 2.25rem;
          font-weight:300;
        }
      }
    }
    .character-list{
      margin: 15px 0;
      .character-item{
        background-color: @navbar-default-bg;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        min-height: 250px;
        .character-data{
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          color: #ffffff;
          font-weight: 300;
          font-size: 1.05rem;
          text-transform: uppercase;
          padding: 5px;
          border: 2px solid transparent;
          text-shadow: 1px 1px 0 black;
        }
      }
      .people-list {
        .person-item {
          display: block;
          border-top: 1px solid @body-bg;
          .person-data{
            .person-name{
              color:@navbar-default-bg;
            }
            .person-role{
              font-weight: 300;
              text-transform: uppercase;
              font-size: 1.05rem;
              color: @navbar-default-bg;
            }
          }
        }
      }
    }
    .chapters-container,.episodes-container{
      .chapter-thumbnail,.episode-thumbnail{
        padding: 0;
        .picture{
          max-height: 100px;
          width: 100%;
          background: @body-bg;
        }
      }
      .chapter-data,.episode-data{
        line-height: 100%;
        .chapter-title,.episode-title{
          color: @navbar-default-bg;
          font-weight: bold;
        }
        .chapter-overview,.episode-overview{
          color: @navbar-default-link-color;
          max-width: 100%;
          -webkit-line-clamp: 5;
        }
      }
    }
    .volume-container{

      .volume-thumbnail{
        padding: 0;
        .picture{
          max-height: 100px;
          min-height: 70px;
          width: 100%;
        }
      }
      .volume-data{
        line-height: 100%;
        .volume-title{
          color: @navbar-default-bg;
          font-weight: bold;
        }
      }
    }
    .file-sections{
      list-style: none;
      float: right;
      li{
        display: inline-block;
        a{
          line-height: 2.2rem;
          .file-section-title{
            font-size: 2.2rem;
          }
        }
        &.active{
          border-bottom-width: 2px;
          border-bottom-style: solid;
          a{
            .file-section-title{
              font-weight: bold;
            }
          }
        }
      }
    }
    .relation-thumbnail{
      position: relative;
      height:auto;
      margin: 5px;
      .relation-thumbnail-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        color: #ffffff;
        font-weight: 300;
        font-size: 1.05rem;
        text-transform: uppercase;
        padding: 5px;
        border: 2px solid transparent;
        text-shadow: 1px 1px 0 black;
        .relation-name {
          display: block;
        }
        .relation-type {
          display: block;
          &:before{
            font-family: FontAwesome;
            content: "\f126";
            color: @brand-primary;
            margin-right: 5px;
          }
        }
      }
    }
    .company-thumbnail{
      position: relative;
      height:auto;
      margin: 5px;
      .company-thumbnail-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        color: #ffffff;
        font-weight: 300;
        font-size: 1.05rem;
        text-transform: uppercase;
        padding: 5px;
        border: 2px solid transparent;
        text-shadow: 1px 1px 0 black;
        .company-name {
          display: block;
        }
        .company-periodicity{
          display: block;
          &:before{
            font-family: FontAwesome;
            content: "\f073";
            color: @brand-primary;
            margin-right: 5px;
          }
        }
        .company-rol{
          display: block;
          &:before{
            font-family: FontAwesome;
            content: "\f013";
            color: @brand-primary;
            margin-right: 5px;
          }
        }
        .company-producer {
          display: block;
          &:before{
            font-family: FontAwesome;
            content: "\f03d";
            color: @brand-primary;
            margin-right: 5px;
          }
        }
        .company-licensor {
          display: block;
          &:before{
            font-family: FontAwesome;
            content: "\f1c8";
            color: @brand-primary;
            margin-right: 5px;
          }
        }
        .company-studio {
          display: block;
          &:before{
            font-family: FontAwesome;
            content: "\f1fc";
            color: @brand-primary;
            margin-right: 5px;
          }
        }
      }
    }
    .person-thumbnail{
      position: relative;
      height:auto;
      text-align: center;
      margin: 5px;
      .person-thumbnail-container {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          color: #ffffff;
          font-weight: 300;
          font-size: 1.05rem;
          text-transform: uppercase;
          padding: 5px;
          border: 2px solid transparent;
          text-shadow: 1px 1px 0 black;
        .person-name {
          display: block;
        }
        .person-role {
          display: block;
          &:before{
            font-family: FontAwesome;
            content: "\f2bc";
            color: @brand-primary;
            margin-right: 5px;
          }
        }
      }
    }
    .character-thumbnail{
      position: relative;
      height:auto;
      text-align: center;
      //margin: 5px;
      .character-thumbnail-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        color: #ffffff;
        font-weight: 300;
        font-size: 1.05rem;
        text-transform: uppercase;
        padding: 5px;
        border: 2px solid transparent;
        text-shadow: 1px 1px 0 black;
        .character-name {
          display: block;
        }
      }
    }
  }
}
.news-thumbnail{
  position: relative;
  text-shadow: 1px 1px 0 black;
  min-height: 18.25rem;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  &.bg-news-one_shot{
    background-image: radial-gradient(at 10% 30%, @one-shot-color 0%, darken(@one-shot-color, 30%) 100%);
  }
  &.bg-news-manga{
    background-image: radial-gradient(at 10% 30%, @manga-color 0%, darken(@manga-color, 30%) 100%);
  }
  &.bg-news-manhwa{
    background-image: radial-gradient(at 10% 30%, @manhwa-color 0%, darken(@manhwa-color, 30%) 100%);
  }
  &.bg-news-manhua{
    background-image: radial-gradient(at 10% 30%, @manhua-color 0%, darken(@manhua-color, 30%) 100%);
  }
  &.bg-news-novel{
    background-image: radial-gradient(at 10% 30%, @novel-color 0%, darken(@novel-color, 30%) 100%);
  }
  &.bg-news-doujinshi{
    background-image: radial-gradient(at 10% 30%, @doujinshi-color 0%, darken(@doujinshi-color, 30%) 100%);
  }
  &.bg-news-oel{
    background-image: radial-gradient(at 10% 30%, @oel-color 0%, darken(@oel-color, 30%) 100%);
  }
  &.bg-news-serie{
    background-image: radial-gradient(at 10% 30%, @serie-color 0%, darken(@serie-color, 30%) 100%);
  }
  &.bg-news-film{
    background-image: radial-gradient(at 10% 30%, @film-color 0%, darken(@film-color, 30%) 100%);
  }
  &.bg-news-ova{
    background-image: radial-gradient(at 10% 30%, @ova-color 0%, darken(@ova-color, 30%) 100%);
  }
  &.bg-news-clip{
    background-image: radial-gradient(at 10% 30%, @clip-color 0%, darken(@clip-color, 30%) 100%);
  }
  &.bg-news-dorama{
    background-image: radial-gradient(at 10% 30%, @dorama-color 0%, darken(@dorama-color, 30%) 100%);
  }
  &.bg-news-company{
    background-image: radial-gradient(at 10% 30%, @company-color 0%, darken(@company-color, 30%) 100%);
  }
  &.bg-news-person{
    background-image: radial-gradient(at 10% 30%, @person-color 0%, darken(@person-color, 30%) 100%);
  }
  &.bg-news-character{
    background-image: radial-gradient(at 10% 30%, @character-color 0%, darken(@character-color, 30%) 100%);
  }
  &.bg-news-discussion{
    background-image: radial-gradient(at 10% 30%, @discussion-color 0%, darken(@discussion-color, 30%) 100%);
  }
  &.bg-news-list{
    background-image: radial-gradient(at 10% 30%, @list-color 0%, darken(@list-color, 30%) 100%);
  }
  &.bg-news-culture{
    background-image: radial-gradient(at 10% 30%, @culture-color 0%, darken(@culture-color, 30%) 100%);
  }
  &.bg-news-game{
    background-image: radial-gradient(at 10% 30%, @game-color 0%, darken(@game-color, 30%) 100%);
  }
  &.bg-news-other{
    background-image: radial-gradient(at 10% 30%, @other-color 0%, darken(@other-color, 30%) 100%);
  }
  &.bg-news-system{
    background-image: radial-gradient(at 10% 30%, @system-color 0%, darken(@system-color, 30%) 100%);
  }
  &.bg-news-hentai{
    background-image: radial-gradient(at 10% 30%, @hentai-color 0%, darken(@hentai-color, 30%) 100%);
  }
  &.bg-news-user{
    background-image: radial-gradient(at 10% 30%, @user-color 0%, darken(@user-color, 30%) 100%);
  }
  &:before {
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    background-position: center center;
    opacity: 0.35;
    z-index: 0;
  }
  &.editor{
    min-height: 38.5rem;
    .news-thumbnail-description {
      font-size: 14px;
      line-height: 2.5rem;
      max-height: 30rem;
      p{
        max-height: 27.5rem;
        -webkit-line-clamp:11;
      }
    }
  }
  .news-thumbnail-title {
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    padding: 5px;
    border: 2px solid transparent;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    a{
      h4 {
        color: #ffffff !important;
      }
    }
  }

  .news-thumbnail-description {
    color: #ffffff;
    font-size: 13px;
    padding: 5px;
    position: relative;
    line-height: 2rem;
    max-height: 10rem;
    overflow: hidden;
    z-index: 1;
    p{
      margin-bottom: 0;
      display: -webkit-box;
      max-height: 8rem;
      -webkit-line-clamp:4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: -o-ellipsis-lastline;
    }
    a{
      color: #ffffff !important;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .news-thumbnail-container {
    width: 100%;
    color: #d8dbdf;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    padding: 5px;
    border: 2px solid transparent;
    position: absolute;
    z-index: 1;
    bottom: 0;
    text-align: right;
    .news-owner{
      &:before{
        font-family: 'FontAwesome';
        content: "\f2be";
        margin-right: 5px;
      }
      margin: 0 5px;
    }
    .news-date{
      &:before{
        font-family: 'FontAwesome';
        content: "\f073";
        margin-right: 5px;
      }
      margin: 0 5px;
    }
    .news-views{
      &:before{
        font-family: 'FontAwesome';
        content: "\f06e";
        margin-right: 5px;
      }
      margin: 0 5px;
    }
  }
}
@media (min-width: @screen-sm-min) {
  .file-container{
    //max-height: 500px;
    .file-header{
      //max-height: 500px;
      .background,.wallpaper{
        //max-height: 500px;
      }
    }
    .file-section-header{
      max-height: 250px;
      .background,.wallpaper{
        max-height: 250px;
      }
    }
    .file-content-container{
      .file-sections{
        list-style: none;
        float: right;
        li{
          float: left;
          a{
            display: block;
          }
        }
      }
    }
  }
}
.news-filter{
  list-style: none;
  margin-left: 10px;
  padding: 0;
  li {
    h3 {
      a {
        width: 100%;
        display: block;
        border-bottom-style: solid;
        border-bottom-width: 0px;
        transition: border-bottom-width .3s ease;
        &:hover{
          border-bottom-width: 5px;
        }
        &.active {
          border-bottom-width: 5px;
        }
      }
    }
  }
}
.emision-row{
  background: #ffffff;
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  border-radius:2px;
  border:1px solid #ccc;
  padding: 6px 20px;
  margin: 2px 0;
  &:hover{
    background: @brand-primary;
    color: #ffffff;
    span{
      color: #ffffff;
    }
    .fa-podcast{
      animation: fadeIn 1s infinite alternate;
    }
  }
}
.load-changes{
  cursor: pointer;
  border-radius: @border-radius-base;
  border: 1px solid @navbar-default-bg;
  padding: 10px 15px;
  color: @navbar-default-bg;
  display: inline-block;
  &:before{
    font-family: FontAwesome;
    content: "\f022";
    margin-right: 5px;
  }
  &:hover{
    background: @navbar-default-bg;
    color: @navbar-default-link-color;
  }
}
.navbar {
  min-height: 60px;
  font-weight: 300;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  background: transparent;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background: transparent;
}

.btn-edit {
  color: @text-color;
  background: @brand-primary;
  border: 1px solid darken(@brand-primary, 6.5%);
  outline: none !important;
}

.list-container{
  .indicators-container {
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    height:2.4em;
    line-height: 2.4;
    background-color: rgba(0, 0, 0, .7);
    text-shadow: 1px 1px 0px black;
    color: #ffffff;
    border: 2px solid transparent;
    .list-indicators {
      color: #ffffff;
      line-height: 2.4;
    }
  }
  .list-image{
    width: 100%;
    margin:0 auto;
  }
  .text-truncate{
    -webkit-line-clamp:5;
    max-width: 100%;
    width:100%;
  }
}
.list-group-item{
  &.collaborator {
    .badge {
      min-width: 15px;
      padding: 10px 10px;
      background-color: @navbar-default-bg;
    }
    img{
      height: 35px;
    }
    h3{
      display: inline-flex;
    }
  }
}
header {
  nav.navbar {
    min-height: 60px;
    border-bottom: 0;
    a {
      text-transform: uppercase;
    }
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    div.navbar-collapse {
      ul.navbar-nav > li > a {
        font-size: 13px !important;
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    nav.affix-top {
      div.container {
        height: 131px;
        transition: 0.5s ease;
        div.navbar-header {
          .navbar-brand {
            float: left;
            padding: 30px 15px;
            font-size: 25px;
            line-height: 70px;
            height: 131px;
            transition: 0.5s ease;
          }
        }
        div.navbar-collapse {
          ul.navbar-nav > li > a {
            padding-top: 55px;
            padding-bottom: 55px;
            transition: 0.5s ease;
            font-size: 17px;
          }
        }
        @media (min-width: @screen-sm-min) {
          #anime_dropdown_btn {
            #anime-dropdown {
              display: none;
              background: none;
              box-shadow: none;
              border: 0;
              margin-top: -60px;
              margin-left: 5px;
              li {
                float: left;
                padding: 0px;
                margin: 0;
                a {
                  margin: 0;
                  padding: 5px 10px;
                  background: transparent;
                  color: @navbar-default-link-color;
                }
                a:hover {
                  color: @navbar-default-link-active-color;
                }
              }
            }
          }

          #anime_dropdown_btn:hover {
            #anime-dropdown {
              display: inline-block;
            }
          }
        }
      }
    }

    nav.affix {
      div.container {
        height: 60px;
        transition: 0.5s ease;
        div.navbar-header {
          .navbar-brand {
            float: left;
            padding: 20px 15px;
            font-size: 18px;
            line-height: 20px;
            height: 60px;
            transition: 0.5s ease;
          }
        }
        div.navbar-collapse {
          ul.navbar-nav > li {
            line-height: 20px;
            height: 19px;
            .dropdown-menu{
              margin-top: 40px;
            }
          }
          ul.navbar-nav > li > a {
            padding-top: 20px;
            padding-bottom: 20px;
            transition: 0.5s ease;
          }
        }
        @media (min-width: 768px) {
          #anime_dropdown_btn {
            #anime-dropdown {
              display: none;
              background: none;
              box-shadow: none;
              border: 0;
              margin-top: 10px;
              margin-left: 5px;
              li {
                float: left;
                padding: 0px;
                margin: 0;
                a {
                  margin: 0;
                  padding: 0px 10px;
                  background: transparent;
                  color: @navbar-default-link-color;
                }
                a:hover {
                  color: @navbar-default-link-active-color;
                }
              }
            }
          }

          #anime_dropdown_btn:hover {
            #anime-dropdown {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  #searchBar {
    background-color: #fff;
    border-bottom: 1px solid @navbar-default-link-color;
    z-index: 1029;
    .search-box {
      outline: none;
      .search-icon{
        line-height: 1.5;
        font-size: 1.5em;
        color: @navbar-default-link-color;
      }
      .search-label{
        text-transform: uppercase;
        margin: 0;
        font-size: 15px;
        border-width: 2px;
        border-radius: 2px;
        border-style: solid;
      }
      .search-clear{
        line-height: 1.70;
        font-size: 1.4em;
      }
    }
    .search-results{
      width:100%;
      background: #ffffff;
      border-bottom: 1px solid @navbar-default-link-color;
      max-height:466px;
      overflow-y: auto;
      .list-group-item:hover{
        background-color: fade(@navbar-default-bg,20%);
      }
      .list-group-item{
        border:none;
        vertical-align: middle;
        line-height: 93.48px;
        img{
          max-width:50px;
          border: 1px solid @navbar-default-link-color;
          margin: 0 15px 0 0;
        }
        label{
          text-transform: uppercase;
          margin: 35px 0;
          font-size: 13px;
          border-width: 2px;
          border-radius: 2px;
          border-style: solid;
        }
        i.fa-list{
          height: 74px;
          line-height: 2.25;
          width: 50px;
        }
        .tt-highlight{
          color: @brand-primary;
        }
      }
    }
    section.search {
      height: 64px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      transition: 0.4s ease;
      form {
        width: 100%;
        input[type=text] {
          width: 55%;
          height: 34px;
          line-height: 35px;
          border: none;
          outline: none;
          font-style: italic;
          font-weight: 400;
          font-size: 1.3em;
          color: @brand-primary;
          box-sizing: border-box;
          text-indent: 0;
          /*padding-left: 40px;
          padding-right: 40px;*/
        }
        @media (min-width: @screen-sm-min) {
          input[type=text] {
            width: 80%;
          }
        }
        input[type=submit] {
          display: none;
        }
      }
    }
  }
  #searchBar {
    height: 40px;
    display: block;
    top: 0;
    margin-top: 60px;
    transition: 0.4s ease;
    section.search {
      height: 40px;
      transition: 0.4s ease;
    }
  }
  @media (min-width: @screen-sm-min) {
    #searchBar.affix-top {
      height: 60px;
      display: block;
      top: 0;
      margin-top: 131px;
      transition: 0.4s ease;
      .search {
        height: 60px;
        transition: 0.4s ease;
      }
    }

    #searchBar.affix {
      height: 40px;
      display: block;
      top: 0;
      margin-top: 60px;
      transition: 0.4s ease;
      .search {
        height: 40px;
        transition: 0.4s ease;
      }
    }
  }
}

.overall-score{
  position: relative;
  border-radius: 50%;
  .score{
    color: @navbar-default-bg !important;
    line-height: 75px;
    font-weight: 300;
    font-size: 40px;
    position: relative;
  }
  &:before{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    color: @star-color;
    opacity: 0.6;
    line-height: 75px;
    font-size: 90px;
    font-family: FontAwesome;
    content: '\f005';
  }
}
footer {
  background-color: darken(@navbar-default-bg,13%);
  &:before{
    background-color: darken(@navbar-default-bg,13%);
    position: absolute;
    filter: grayscale(1) opacity(0.2);
    background-position: center center;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  margin-top: 15px;
  padding: 48px 0;
  color: @navbar-default-link-color;
  position: relative;
  font-size: 1.15rem;
  a {
    color: @navbar-default-link-color;
  }
  a:hover {
    color: @brand-primary;
    text-decoration: none;
  }
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.no-results{
  color: @gray-darker;
}
.no-results-icon{
  font-size: 10em;
  color:@gray-light;
}

@media (min-width: 992px) {
  .footer-section {
    padding: 32px 0;
  }
}

.navbar-default.affix {
  border-color: rgba(34, 34, 34, .05);
}

.footer-section ul > li {
  margin-bottom: 8px;
}

.footer-section .form-control, .footer-section .form-control-default {
  background: darken(@navbar-default-bg,13%);
  border-color: darken(@navbar-default-bg,10%);
  color: @navbar-default-link-color;
  font-size: 1.15rem;
}

.footer-section label {
  color: @navbar-default-link-color;
}

.footer-section .h1, .footer-section .h2, .footer-section .h3, .footer-section .h4, .footer-section .h5, .footer-section .h6, .footer-section h1, .footer-section h2, .footer-section h3, .footer-section h4, .footer-section h5, .footer-section h6 {
  color: @brand-primary;
}

h1, h2, h3, h4, h5, h6 {
  color: @brand-primary;
  span{
    color:@navbar-default-bg;
  }
}
h1, h2, h3, h4{
  font-weight: 300;
}
.form-horizontal-edit,.form-horizontal-create{
  .form-group{
    margin-bottom: 15px;
    display: flow-root;
  }
}
.form-group{
  .control-label{
    line-height:40px;
  }
  div >.form-control,input.form-control{
    line-height:50px;
    height:50px;
  }
  textarea.form-control{
    line-height:25px;
    height:50px;
  }
}

.navbar-header > .navbar-brand > a,
.navbar-header > .navbar-brand > a:hover,
.navbar-header > .navbar-brand > a:visited,
.navbar-header > .navbar-brand > a:active {
  text-decoration: none;
}

.nav-item > .nav-link {
  font-size: 1.7rem;
  font-weight: 400;
}
.nav-username{
  max-width: 200px;
  display: inline-flex;
}

.navbar-header > .navbar-brand {
  border-right: 1px solid @navbar-default-border
}

.lbl-social {
  text-shadow: 0px 2px 0 rgba(0, 0, 0, 0.55);
}
.lbl-facebook {
  color: @facebook-color;
}

.lbl-google {
  color: @google-color;
}

.lbl-twitter {
  color: @twitter-color;
}

.lbl-vkontakte {
  color: @vkontakte-color;
}

.lbl-web {
  color: #93C9D2;
}
.btn-login-social{

}
.bg-facebook {
  color: #ffffff;
  background-color: @facebook-color;
}

.bg-google {
  color: #757575;
  background-color: @google-color;
}

.bg-twitter {
  color: #ffffff;
  background-color: @twitter-color;
}

.bg-vkontakte {
  color: #ffffff;
  background-color: @vkontakte-color;
}
.bg-facebook,.bg-google,.bg-twitter,.bg-vkontakte{
  color: #ffffff;
  &:hover{
    color: @navbar-default-link-hover-color;
  }
}

.vk {
  background-color: #587ea3;
}

.btn-social-fb {
  color: #ffffff;
  background-color: #3f51b5;
  border-color: darken(#3f51b5, 13.5%);
}

.btn-social-fb:hover,
.btn-social-fb:focus,
.btn-social-fb.focus,
.btn-social-fb:active,
.btn-social-fb.active,
.open > .dropdown-toggle.btn-social-fb {
  color: #ffffff;
  background-color: #3f51b5;
  border-color: darken(#3f51b5, 13.5%);
  transition: all 0.5s ease;
}

.btn-social-fb:active,
.btn-social-fb.active,
.open > .dropdown-toggle.btn-social-fb {
  background-image: none;
}

.btn-social-fb.disabled,
.btn-social-fb[disabled],
fieldset[disabled] .btn-social-fb,
.btn-social-fb.disabled:hover,
.btn-social-fb[disabled]:hover,
fieldset[disabled] .btn-social-fb:hover,
.btn-social-fb.disabled:focus,
.btn-social-fb[disabled]:focus,
fieldset[disabled] .btn-social-fb:focus,
.btn-social-fb.disabled.focus,
.btn-social-fb[disabled].focus,
fieldset[disabled] .btn-social-fb.focus,
.btn-social-fb.disabled:active,
.btn-social-fb[disabled]:active,
fieldset[disabled] .btn-social-fb:active,
.btn-social-fb.disabled.active,
.btn-social-fb[disabled].active,
fieldset[disabled] .btn-social-fb.active {
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.btn-social-fb .badge {
  color: #3f51b5;
  background-color: #ffffff;
}

.btn-social-fb.btn-stroke {
  color: @navbar-default-link-color;
  background-color: transparent;
  border-color: @navbar-default-link-color;
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.btn-social-fb.btn-stroke:hover,
.btn-social-fb.btn-stroke:focus,
.btn-social-fb.btn-stroke.focus,
.btn-social-fb.btn-stroke:active,
.btn-social-fb.btn-stroke.active,
.open > .dropdown-toggle.btn-social-fb.btn-stroke {
  color: #ffffff;
  background-color: #3f51b5;
  border-color: #2f3d88;
}

.btn-social-fb.btn-stroke:active,
.btn-social-fb.btn-stroke.active,
.open > .dropdown-toggle.btn-social-fb.btn-stroke {
  background-image: none;
}

.btn-social-fb.btn-stroke.disabled,
.btn-social-fb.btn-stroke[disabled],
fieldset[disabled] .btn-social-fb.btn-stroke,
.btn-social-fb.btn-stroke.disabled:hover,
.btn-social-fb.btn-stroke[disabled]:hover,
fieldset[disabled] .btn-social-fb.btn-stroke:hover,
.btn-social-fb.btn-stroke.disabled:focus,
.btn-social-fb.btn-stroke[disabled]:focus,
fieldset[disabled] .btn-social-fb.btn-stroke:focus,
.btn-social-fb.btn-stroke.disabled.focus,
.btn-social-fb.btn-stroke[disabled].focus,
fieldset[disabled] .btn-social-fb.btn-stroke.focus,
.btn-social-fb.btn-stroke.disabled:active,
.btn-social-fb.btn-stroke[disabled]:active,
fieldset[disabled] .btn-social-fb.btn-stroke:active,
.btn-social-fb.btn-stroke.disabled.active,
.btn-social-fb.btn-stroke[disabled].active,
fieldset[disabled] .btn-social-fb.btn-stroke.active {
  background-color: #ffffff;
  border-color: #3f51b5;
}

.btn-social-fb.btn-stroke .badge {
  color: #ffffff;
  background-color: #3f51b5;
}

.btn-social-fb.btn-stroke.active,
.btn-social-fb.btn-stroke:active,
.btn-social-fb.btn-stroke:focus,
.btn-social-fb.btn-stroke:hover,
.btn-social-fb.btn-stroke .open > .dropdown-toggle.btn-social-fb.btn-stroke {
  background-color: #3f51b5;
  border-color: #3f51b5;
  color: #ffffff;
}

.btn-circle.btn {
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.btn-social-tw {
  color: #ffffff;
  background-color: #2196f3;
  border-color: darken(#2196f3, 13.5%);
}

.btn-social-tw:hover,
.btn-social-tw:focus,
.btn-social-tw.focus,
.btn-social-tw:active,
.btn-social-tw.active,
.open > .dropdown-toggle.btn-social-tw {
  color: #ffffff;
  background-color: #2196f3;
  border-color: darken(#2196f3, 13.5%);
  transition: all 0.5s ease;
}

.btn-social-tw:active,
.btn-social-tw.active,
.open > .dropdown-toggle.btn-social-tw {
  background-image: none;
}

.btn-social-tw.disabled,
.btn-social-tw[disabled],
fieldset[disabled] .btn-social-tw,
.btn-social-tw.disabled:hover,
.btn-social-tw[disabled]:hover,
fieldset[disabled] .btn-social-tw:hover,
.btn-social-tw.disabled:focus,
.btn-social-tw[disabled]:focus,
fieldset[disabled] .btn-social-tw:focus,
.btn-social-tw.disabled.focus,
.btn-social-tw[disabled].focus,
fieldset[disabled] .btn-social-tw.focus,
.btn-social-tw.disabled:active,
.btn-social-tw[disabled]:active,
fieldset[disabled] .btn-social-tw:active,
.btn-social-tw.disabled.active,
.btn-social-tw[disabled].active,
fieldset[disabled] .btn-social-tw.active {
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-social-tw .badge {
  color: #2196f3;
  background-color: #ffffff;
}

.btn-social-tw.btn-stroke {
  color: @navbar-default-link-color;
  background-color: transparent;
  border-color: @navbar-default-link-color;
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.btn-social-tw.btn-stroke:hover,
.btn-social-tw.btn-stroke:focus,
.btn-social-tw.btn-stroke.focus,
.btn-social-tw.btn-stroke:active,
.btn-social-tw.btn-stroke.active,
.open > .dropdown-toggle.btn-social-tw.btn-stroke {
  color: #ffffff;
  background-color: #2196f3;
  border-color: #0b76cc;
}

.btn-social-tw.btn-stroke:active,
.btn-social-tw.btn-stroke.active,
.open > .dropdown-toggle.btn-social-tw.btn-stroke {
  background-image: none;
}

.btn-social-tw.btn-stroke.disabled,
.btn-social-tw.btn-stroke[disabled],
fieldset[disabled] .btn-social-tw.btn-stroke,
.btn-social-tw.btn-stroke.disabled:hover,
.btn-social-tw.btn-stroke[disabled]:hover,
fieldset[disabled] .btn-social-tw.btn-stroke:hover,
.btn-social-tw.btn-stroke.disabled:focus,
.btn-social-tw.btn-stroke[disabled]:focus,
fieldset[disabled] .btn-social-tw.btn-stroke:focus,
.btn-social-tw.btn-stroke.disabled.focus,
.btn-social-tw.btn-stroke[disabled].focus,
fieldset[disabled] .btn-social-tw.btn-stroke.focus,
.btn-social-tw.btn-stroke.disabled:active,
.btn-social-tw.btn-stroke[disabled]:active,
fieldset[disabled] .btn-social-tw.btn-stroke:active,
.btn-social-tw.btn-stroke.disabled.active,
.btn-social-tw.btn-stroke[disabled].active,
fieldset[disabled] .btn-social-tw.btn-stroke.active {
  background-color: #ffffff;
  border-color: #2196f3;
}

.btn-social-tw.btn-stroke .badge {
  color: #ffffff;
  background-color: #2196f3;
}

.btn-social-tw.btn-stroke.active,
.btn-social-tw.btn-stroke:active,
.btn-social-tw.btn-stroke:focus,
.btn-social-tw.btn-stroke:hover,
.btn-social-tw.btn-stroke .open > .dropdown-toggle.btn-social-tw.btn-stroke {
  background-color: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}



.btn-social-vk {
  color: #ffffff;
  background-color: #587ea3;
  border-color: darken(#587ea3, 13.5%);;
}

.btn-social-vk:hover,
.btn-social-vk:focus,
.btn-social-vk.focus,
.btn-social-vk:active,
.btn-social-vk.active,
.open > .dropdown-toggle.btn-social-vk {
  color: #ffffff;
  background-color: #587ea3;
  border-color: darken(#587ea3, 13.5%);
  transition: all 0.5s ease;
}

.btn-social-vk:active,
.btn-social-vk.active,
.open > .dropdown-toggle.btn-social-vk {
  background-image: none;
}

.btn-social-vk.disabled,
.btn-social-vk[disabled],
fieldset[disabled] .btn-social-vk,
.btn-social-vk.disabled:hover,
.btn-social-vk[disabled]:hover,
fieldset[disabled] .btn-social-vk:hover,
.btn-social-vk.disabled:focus,
.btn-social-vk[disabled]:focus,
fieldset[disabled] .btn-social-vk:focus,
.btn-social-vk.disabled.focus,
.btn-social-vk[disabled].focus,
fieldset[disabled] .btn-social-vk.focus,
.btn-social-vk.disabled:active,
.btn-social-vk[disabled]:active,
fieldset[disabled] .btn-social-vk:active,
.btn-social-vk.disabled.active,
.btn-social-vk[disabled].active,
fieldset[disabled] .btn-social-vk.active {
  background-color: #587ea3;
  border-color: #587ea3;
}

.btn-social-vk .badge {
  color: #587ea3;
  background-color: #ffffff;
}

.btn-social-vk.btn-stroke {
  color: @navbar-default-link-color;
  background-color: transparent;
  border-color: @navbar-default-link-color;
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.btn-social-vk.btn-stroke:hover,
.btn-social-vk.btn-stroke:focus,
.btn-social-vk.btn-stroke.focus,
.btn-social-vk.btn-stroke:active,
.btn-social-vk.btn-stroke.active,
.open > .dropdown-toggle.btn-social-vk.btn-stroke {
  color: #ffffff;
  background-color: #587ea3;
  border-color: #4a6f94;
}

.btn-social-vk.btn-stroke:active,
.btn-social-vk.btn-stroke.active,
.open > .dropdown-toggle.btn-social-vk.btn-stroke {
  background-image: none;
}

.btn-social-vk.btn-stroke.disabled,
.btn-social-vk.btn-stroke[disabled],
fieldset[disabled] .btn-social-vk.btn-stroke,
.btn-social-vk.btn-stroke.disabled:hover,
.btn-social-vk.btn-stroke[disabled]:hover,
fieldset[disabled] .btn-social-vk.btn-stroke:hover,
.btn-social-vk.btn-stroke.disabled:focus,
.btn-social-vk.btn-stroke[disabled]:focus,
fieldset[disabled] .btn-social-vk.btn-stroke:focus,
.btn-social-vk.btn-stroke.disabled.focus,
.btn-social-vk.btn-stroke[disabled].focus,
fieldset[disabled] .btn-social-vk.btn-stroke.focus,
.btn-social-vk.btn-stroke.disabled:active,
.btn-social-vk.btn-stroke[disabled]:active,
fieldset[disabled] .btn-social-vk.btn-stroke:active,
.btn-social-vk.btn-stroke.disabled.active,
.btn-social-vk.btn-stroke[disabled].active,
fieldset[disabled] .btn-social-vk.btn-stroke.active {
  background-color: #ffffff;
  border-color: #587ea3;
}

.btn-social-vk.btn-stroke .badge {
  color: #ffffff;
  background-color: #587ea3;
}

.btn-social-vk.btn-stroke.active,
.btn-social-vk.btn-stroke:active,
.btn-social-vk.btn-stroke:focus,
.btn-social-vk.btn-stroke:hover,
.btn-social-vk.btn-stroke .open > .dropdown-toggle.btn-social-vk.btn-stroke {
  background-color: #587ea3;
  border-color: #4a6f94;
  color: #ffffff;
}


.btn-social-web {
  color: #ffffff;
  background-color: #93c9d2;
  border-color: darken(#93c9d2, 6%);;
}

.btn-social-web:hover,
.btn-social-web:focus,
.btn-social-web.focus,
.btn-social-web:active,
.btn-social-web.active,
.open > .dropdown-toggle.btn-social-web {
  color: #ffffff;
  background-color: darken(#93c9d2, 6%);
  border-color: darken(#93c9d2, 13.5%);
}

.btn-social-web:active,
.btn-social-web.active,
.open > .dropdown-toggle.btn-social-web {
  background-image: none;
}

.btn-social-web.disabled,
.btn-social-web[disabled],
fieldset[disabled] .btn-social-web,
.btn-social-web.disabled:hover,
.btn-social-web[disabled]:hover,
fieldset[disabled] .btn-social-web:hover,
.btn-social-web.disabled:focus,
.btn-social-web[disabled]:focus,
fieldset[disabled] .btn-social-web:focus,
.btn-social-web.disabled.focus,
.btn-social-web[disabled].focus,
fieldset[disabled] .btn-social-web.focus,
.btn-social-web.disabled:active,
.btn-social-web[disabled]:active,
fieldset[disabled] .btn-social-web:active,
.btn-social-web.disabled.active,
.btn-social-web[disabled].active,
fieldset[disabled] .btn-social-web.active {
  background-color: #93c9d2;
  border-color: #93c9d2;
}

.btn-social-web .badge {
  color: #93c9d2;
  background-color: #ffffff;
}

.btn-social-web.btn-stroke {
  color: @navbar-default-link-color;
  background-color: transparent;
  border-color: @navbar-default-link-color;
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.btn-social-web.btn-stroke:hover,
.btn-social-web.btn-stroke:focus,
.btn-social-web.btn-stroke.focus,
.btn-social-web.btn-stroke:active,
.btn-social-web.btn-stroke.active,
.open > .dropdown-toggle.btn-social-web.btn-stroke {
  color: #93c9d2;
  background-color: #e6e6e6;
  border-color: #93c9d2;
}

.btn-social-web.btn-stroke:active,
.btn-social-web.btn-stroke.active,
.open > .dropdown-toggle.btn-social-web.btn-stroke {
  background-image: none;
}

.btn-social-web.btn-stroke.disabled,
.btn-social-web.btn-stroke[disabled],
fieldset[disabled] .btn-social-web.btn-stroke,
.btn-social-web.btn-stroke.disabled:hover,
.btn-social-web.btn-stroke[disabled]:hover,
fieldset[disabled] .btn-social-web.btn-stroke:hover,
.btn-social-web.btn-stroke.disabled:focus,
.btn-social-web.btn-stroke[disabled]:focus,
fieldset[disabled] .btn-social-web.btn-stroke:focus,
.btn-social-web.btn-stroke.disabled.focus,
.btn-social-web.btn-stroke[disabled].focus,
fieldset[disabled] .btn-social-web.btn-stroke.focus,
.btn-social-web.btn-stroke.disabled:active,
.btn-social-web.btn-stroke[disabled]:active,
fieldset[disabled] .btn-social-web.btn-stroke:active,
.btn-social-web.btn-stroke.disabled.active,
.btn-social-web.btn-stroke[disabled].active,
fieldset[disabled] .btn-social-web.btn-stroke.active {
  background-color: #ffffff;
  border-color: #93c9d2;
}

.btn-social-web.btn-stroke .badge {
  color: #ffffff;
  background-color: #93c9d2;
}

.btn-social-web.btn-stroke.active,
.btn-social-web.btn-stroke:active,
.btn-social-web.btn-stroke:focus,
.btn-social-web.btn-stroke:hover,
.btn-social-web.btn-stroke .open > .dropdown-toggle.btn-social-web.btn-stroke {
  background-color: #93c9d2;
  border-color: #93c9d2;
  color: #ffffff;
}

.btn-social-gp {
  color: #757575;
  background-color: #ffffff;
  border-color: #cacaca;
}

.btn-social-gp:hover,
.btn-social-gp:focus,
.btn-social-gp.focus,
.btn-social-gp:active,
.btn-social-gp.active,
.open > .dropdown-toggle.btn-social-gp {
  color: #757575;
  background-color: #ffffff;
  border-color: #cacaca;
  transition: all 0.5s ease;
}

.btn-social-gp:active,
.btn-social-gp.active,
.open > .dropdown-toggle.btn-social-gp {
  background-image: none;
}

.btn-social-gp.disabled,
.btn-social-gp[disabled],
fieldset[disabled] .btn-social-gp,
.btn-social-gp.disabled:hover,
.btn-social-gp[disabled]:hover,
fieldset[disabled] .btn-social-gp:hover,
.btn-social-gp.disabled:focus,
.btn-social-gp[disabled]:focus,
fieldset[disabled] .btn-social-gp:focus,
.btn-social-gp.disabled.focus,
.btn-social-gp[disabled].focus,
fieldset[disabled] .btn-social-gp.focus,
.btn-social-gp.disabled:active,
.btn-social-gp[disabled]:active,
fieldset[disabled] .btn-social-gp:active,
.btn-social-gp.disabled.active,
.btn-social-gp[disabled].active,
fieldset[disabled] .btn-social-gp.active {
  background-color: #ffffff;
  border-color: #cacaca;
}

.btn-social-gp .badge {
  color: #757575;
  background-color: #ffffff;
}

.btn-social-gp.btn-stroke {
  color: @navbar-default-link-color;
  background-color: transparent;
  border-color: @navbar-default-link-color;
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.btn-social-gp.btn-stroke:hover,
.btn-social-gp.btn-stroke:focus,
.btn-social-gp.btn-stroke.focus,
.btn-social-gp.btn-stroke:active,
.btn-social-gp.btn-stroke.active,
.open > .dropdown-toggle.btn-social-gp.btn-stroke {
  color: #757575;
  background-color: #ffffff;
  border-color: #cacaca;
}

.btn-social-gp.btn-stroke:active,
.btn-social-gp.btn-stroke.active,
.open > .dropdown-toggle.btn-social-gp.btn-stroke {
  background-image: none;
}

.btn-social-gp.btn-stroke.disabled,
.btn-social-gp.btn-stroke[disabled],
fieldset[disabled] .btn-social-gp.btn-stroke,
.btn-social-gp.btn-stroke.disabled:hover,
.btn-social-gp.btn-stroke[disabled]:hover,
fieldset[disabled] .btn-social-gp.btn-stroke:hover,
.btn-social-gp.btn-stroke.disabled:focus,
.btn-social-gp.btn-stroke[disabled]:focus,
fieldset[disabled] .btn-social-gp.btn-stroke:focus,
.btn-social-gp.btn-stroke.disabled.focus,
.btn-social-gp.btn-stroke[disabled].focus,
fieldset[disabled] .btn-social-gp.btn-stroke.focus,
.btn-social-gp.btn-stroke.disabled:active,
.btn-social-gp.btn-stroke[disabled]:active,
fieldset[disabled] .btn-social-gp.btn-stroke:active,
.btn-social-gp.btn-stroke.disabled.active,
.btn-social-gp.btn-stroke[disabled].active,
fieldset[disabled] .btn-social-gp.btn-stroke.active {
  background-color: #ffffff;
  border-color: #cacaca;
}

.btn-social-gp.btn-stroke .badge {
  color: #757575;
  background-color: #ffffff;
}

.btn-social-gp.btn-stroke.active,
.btn-social-gp.btn-stroke:active,
.btn-social-gp.btn-stroke:focus,
.btn-social-gp.btn-stroke:hover,
.btn-social-gp.btn-stroke .open > .dropdown-toggle.btn-social-gp.btn-stroke {
  background-color: #ffffff;
  border-color: #cacaca;
  color: #757575;
}


.btn-social{
  color: #fff;
  margin: 5px 0;
  &:hover{
    color: white;
    text-decoration: none;
  }
  &.btn-web{
    background-color: @brand-primary;
    line-height: 1rem !important;
  }
  &.btn-facebook{
    background-color: @facebook-color;
    line-height: 1rem !important;
  }
  &.btn-twitter{
    background-color: @twitter-color;
    line-height: 1rem !important;
  }
  &.btn-google{
    background-color: @google-color;
    line-height: 1rem !important;
  }
  &.btn-vkontakte{
    background-color: @vkontakte-color;
    line-height: 1rem !important;
  }
  &.btn-youtube{
    background-color: @youtube-color;
    line-height: 1rem !important;
  }
  &.btn-telegram{
    background-color: @telegram-color;
    line-height: 1rem !important;
  }
  &.btn-whatsapp{
    background-color: @whatsapp-color;
    line-height: 1rem !important;
  }
}
.panel > .panel-heading {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.progress-sm {
  padding: 0px;
  height: 10px;
  margin: 5px 0px;
  vertical-align: middle;
}

.lazy {
  max-width: 100%;
  background-color: @body-bg;
  background-image: url('/images/defaults/loading.gif');
  height: auto;
  display: none;
}

.table-responsive .table {
  max-width: none;
}
.modal{
  .modal-header{
    background-color: @navbar-default-bg;
    font-weight: bold;
    text-transform: uppercase;
    .close{
      color: @brand-primary;
      text-shadow: none;
      opacity: 1;
    }
  }
}
table tr td a {
  display: block;
  height: 100%;
  width: 100%;
}

table tr td {
  padding-left: 0;
  padding-right: 0;
}

.uppercase{
  text-transform: uppercase;
}
.lowercase{
  text-transform: lowercase;
}
.capitalize{
  text-transform: capitalize;
}
.line-truncate {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordion-toggle{
  cursor: pointer;
}
.accordion-toggle:after {
  font-family: 'FontAwesome';
  content: "\f078";
  margin-right: 10px;
  float: right;
}
.accordion-toggle.collapsed:after {
  content: "\f054";
}
.tt-menu > .tt-dataset {
  p.list-group-item {
    white-space: nowrap;
    i {
      text-transform: uppercase;
    }
  }
}

.twitter-typeahead {
  width: 100%;
  line-height: 2.75;
}

.cookie-consent {
  padding: 25px;
  color: @navbar-default-color;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: @zindex-navbar-fixed;
  background-color: @navbar-default-bg;
  border-color: transparent;
  border-radius: 0;
  .btn-cookie{
    color: @navbar-default-bg;
    background-color: @brand-primary;
    border-color: darken(@brand-primary, 6.5%);
  }
}

.btn-group-vertical {
  .bootstrap-select {
    width: 100% !important;
    float: none !important;
    .dropdown-menu .open {
      min-width: 100% !important;
    }
  }
}

.page_wrap {
  margin-top: 100px;
  min-height: 25vh;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

#vk_share_button{
  width: 95px !important;
  text-align: center;
  margin: 0 auto;
}
#___plus_0{
  width: 84px !important;
}
.btn-social-wp {
  color: #ffffff;
  background-color: #25D366;
  border-color: darken(#25D366, 13.5%);
  width: 100px;
  padding: 5px;
  margin: 5px auto;
  a {
    color: #ffffff;
    text-decoration: none;
  }
}

.corner-ribbon{
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 100;
}

/* Custom styles */

.corner-ribbon.sticky{
  position: absolute;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-left{
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right{
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left{
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */

.corner-ribbon.white{background: #f0f0f0; color: #555;}
.corner-ribbon.black{background: #333;}
.corner-ribbon.grey{background: #999;}
.corner-ribbon.blue{background: #39d;}
.corner-ribbon.green{background: #2c7;}
.corner-ribbon.turquoise{background: #1b9;}
.corner-ribbon.purple{background: #95b;}
.corner-ribbon.red{background: #e43;}
.corner-ribbon.orange{background: #e82;}
.corner-ribbon.yellow{background: #ec0;}

.guide{
  .guide-section{
    position: relative;
    background-color: transparent;
    min-height: 150px;
    .background{
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.5;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      &.library{
        background-image: url("/images/guide/bg_library.jpg");
      }
      &.videolibrary{
        background-image: url("/images/guide/bg_videolibrary.jpg");
      }
      &.community{
        background-image: url("/images/guide/bg_community.jpg");
      }
      &.news{
        background-image: url("/images/guide/bg_news.jpg");
      }
      &.users{
        background-image: url("/images/guide/bg_users.jpg");
      }
      &.general{
        background-image: url("/images/guide/bg_general.jpg");
      }
    }

    .guide-section-content{
      position: absolute;
      width: 100%;
      background: rgba(0,0,0,0.5);
      bottom: 0;
      left: 0;
      padding: 0 15px;
      .guide-section-title{
        color: #ffffff;
        text-shadow: 0 0 5px black;
        line-height: 1;
        margin: 5px;
      }
    }
  }
}
.breadcrumb{
  background-color: transparent;
  margin-bottom: 0;
  a{
    color: white;
  }
}
